import React, { Component, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import MetisMenu from "metismenujs";
import SimpleBar from "simplebar-react";
import { compose } from 'redux';
import { connect } from 'react-redux';
import useAuth from "../../pages/Authentication/useAuth";
import logo from "../../assets/images/ei-logo-white.png";
import styled from 'styled-components';
import { Button } from 'reactstrap';
import { PRICING_PAGE } from "../../config/settings";
import moment from 'moment';
import PremiumFeature from "../../components/PremiumFeature";
import { formatDate } from "utils/date";
import usePrevious from "helpers/usePrevious";
import { authHost } from "pages/Authentication/Login";

const SidebarContent = ({ product: p, reseller, device, history, brand, leads }) => {
  const { currentUser } = useAuth();
  const { isAdmin = false } = currentUser || {};
  const type = history?.location.pathname.split('/')[1];
  const isProduct = type === 'products';
  const isReseller = type === 'resellers';
  const isDevice = type === 'devices';
  const isBrand = type === 'brands';
  const product = isProduct ? p : isReseller ? reseller : isBrand ? brand : device;
  const attractsReviews = isProduct || isReseller;
  const attractsLeads = isProduct;
  const baseLink = product && `/${type}/${product.slug}`;
  const { isPremium, pricePerLead, freeLeadCount, paidLeadCount, unlimitedLeads } = product || {};
  const attractsProspects = currentUser?.isAdmin || isPremium || product?.buyerIntent
  const unRepliedMessageCount = leads?.filter(l => l.supplierStatus === 'new').length;
  const planExpiry = product?.planExpiry ? formatDate({ value: product.planExpiry, local: true, friendly: false }) : null;
  const plan = product?.plan ? product.plan.toLowerCase() : '';
  const isLeadBundleUser = !!pricePerLead && pricePerLead > 0
  const isPowerPlan = isPremium && !!unlimitedLeads
  const leadBalance = (freeLeadCount || 0) + (paidLeadCount || 0)

  const showMenu = product && product.slug !== 'add-listing';

  const balanceText = `${(!isPremium || isLeadBundleUser) ? 'Leads remaining' : 'Credit balance'}: ${(!isPremium || isLeadBundleUser) ? `${leadBalance}` : `£${product?.leadCreditBalance || 0}`}`

  // TODO
  const newProspectsCount = 0;

  const renderMenuItem = ({ url, text, isPremium, isAdmin }) => {
    return (
      isPremium
        ? <Link to={`${baseLink}${url}`}>{text}</Link>
        : isAdmin
          ? <Link to={`${baseLink}${url}`} className="premium-feature">{text} <PremiumFeature hideText /></Link>
          : <p className="premium-feature">{text} <PremiumFeature hideText /></p>
    )
  }

  /**
   * For pages that handle free vs premium state properly, we are okay with free users navigating to them and actively getting a preview of things.
   */
  const renderPreviewBasedMenuItem = ({ url, text, isPremium }) => {
    return (
      isPremium
        ? <Link to={`${baseLink}${url}`}>{text}</Link>
        : <Link to={`${baseLink}${url}`} className="premium-feature">{text} <PremiumFeature hideText /></Link>
    )
  }

  return (
    <SidebarMenuDiv id="sidebar-menu">
      <ul className="metismenu list-unstyled" id="side-menu">

        {showMenu &&
          <>
            <li id="special-list-item">
              <a href={`${authHost}/${type}/${product.slug}`} target="_blank">
                <i className="ti-new-window"></i>
                <span style={{ textTransform: "capitalize" }}>Visit profile page</span>
              </a>
            </li>

            {attractsReviews &&
              <li>
                <Link to={`${baseLink}/dashboard`} className="waves-effect">
                  <i className="ti-home"></i>
                  <span>Dashboard</span>
                </Link>
              </li>
            }

            <li>
              <Link to="" className="has-arrow waves-effect">
                <i className="ti-pencil-alt"></i>
                <span>Profile</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to={`${baseLink}/profile`}>Information</Link>
                </li>

                {isReseller &&
                  <li>
                    <Link to={`${baseLink}/devices`}>Devices</Link>
                  </li>
                }

                {/* {isProduct &&
                <li>
                  <Link to={`${baseLink}/special-offer`}>Special Offer</Link>
                </li>
              } */}

                {attractsReviews &&
                  <li>
                    <Link to="" className="has-arrow waves-effect">
                      <i className="ti-heart" style={{ color: 'red', minWidth: '1.5rem' }}></i>
                      <span>Health Check</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to={`${baseLink}/health-check/researched-impact`}>Researched Impact</Link>
                      </li>
                      <li>
                        <Link to={`${baseLink}/health-check/compliance-safety`}>Compliance & Safety</Link>
                      </li>
                      <li>
                        <Link to={`${baseLink}/health-check/learning-engagement`}>Learning Engagement</Link>
                      </li>
                    </ul>
                  </li>
                }

                <>
                  {(isProduct || isReseller) &&
                    <li>
                      <Link to={`${baseLink}/evidence`}>Evidence Upload</Link>
                    </li>
                  }

                </>
              </ul>
            </li>

            {attractsReviews &&
              <li>
                <Link to="" className="has-arrow waves-effect">
                  <i className="ti-star"></i>
                  <span>Reviews</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={`${baseLink}/reviews/your-reviews`}>Your Reviews</Link>
                  </li>

                  {isProduct &&
                    <li>
                      {renderPreviewBasedMenuItem({ url: "/impact-metrics", text: "Impact", isPremium })}
                    </li>
                  }

                  {isReseller &&
                    <li>
                      <Link to={`${baseLink}/impact-metrics`}>Impact</Link>
                    </li>
                  }

                  <li>
                    <Link to="" className="has-arrow waves-effect">Collect</Link>
                    <ul className="sub-menu">
                      <li>
                        {renderPreviewBasedMenuItem({ url: "/reviews/manual-collection", text: "Manual", isPremium })}
                      </li>
                      <li>
                        {isPremium ?
                          <Link to={`${baseLink}/reviews/automatic-collection`}>Automatic</Link>
                          : isAdmin
                            ? <Link to={`${baseLink}/reviews/automatic-collection`} className="premium-feature">Automatic <PremiumFeature hideText /></Link>
                            : <p className="premium-feature">Automatic <PremiumFeature hideText /></p>
                        }
                      </li>
                      <li>
                        {isPremium
                          ? <Link to={`${baseLink}/reviews/upload-invites`}>Upload</Link>
                          : isAdmin
                            ? <Link to={`${baseLink}/reviews/upload-invites`} className="premium-feature">Upload <PremiumFeature hideText /></Link>
                            : <p className="premium-feature">Upload <PremiumFeature hideText /></p>
                        }
                      </li>
                      <li>
                        {isPremium
                          ? <Link to={`${baseLink}/reviews/invitations`}>Invitations</Link>
                          : isAdmin
                            ? <Link to={`${baseLink}/reviews/invitations`} className="premium-feature">Invitations <PremiumFeature hideText /></Link>
                            : <p className="premium-feature">Invitations <PremiumFeature hideText /></p>
                        }
                      </li>

                      {(isPremium || isAdmin) &&
                        <>
                          <li>
                            <Link to={`${baseLink}/reviews/invite-email`}>Invite Email {!isPremium ? <PremiumFeature hideText /> : null}</Link>
                          </li>
                          <li>
                            <Link to={`${baseLink}/reviews/test-invite`}>Send Test Invite {!isPremium ? <PremiumFeature hideText /> : null}</Link>
                          </li>
                        </>
                      }
                    </ul>
                  </li>

                  <li>
                    {isPremium
                      ? <Link to={`${baseLink}/reviews/survey`}>Survey</Link>
                      : isAdmin
                        ? <Link to={`${baseLink}/reviews/survey`} className="premium-feature">Survey <PremiumFeature hideText /></Link>
                        : <p className="premium-feature">Survey <PremiumFeature hideText /></p>
                    }
                  </li>

                </ul>
              </li>
            }

            {attractsLeads &&
              <li>
                <Link to="" className="has-arrow waves-effect">
                  <i className="ti-email"></i>
                  <span>Leads {unRepliedMessageCount > 0 && <span>({unRepliedMessageCount})</span>}</span>
                </Link>
                <ul className="sub-menu">

                  <li>
                    <Link to={`${baseLink}/leads`}>
                      <span>Inbox {unRepliedMessageCount > 0 && <span>({unRepliedMessageCount})</span>}</span>
                    </Link>
                  </li>
                  {/* <li>
                    <Link to={`${baseLink}/leads/templates`}>Reply Templates</Link>
                  </li> */}
                  <li>
                    <Link to={`${baseLink}/leads/preferences`} style={{ textTransform: "capitalize" }}>CTA form settings</Link>
                  </li>
                  <li>
                    <Link to={`${baseLink}/billing#details--balance`}>
                      <span>Manage Balance</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`${baseLink}/visitor-activity/preferences`}>Lead Preferences</Link>
                  </li>

                </ul>
              </li>
            }

            {attractsLeads &&
              <li>
                <Link to={`${baseLink}/visitor-activity`} className="waves-effect">
                  <i className="ti-user"></i>
                  <span>Opportunities {newProspectsCount > 0 && <span>({newProspectsCount})</span>}</span>
                </Link>
              </li>
            }

            {attractsReviews &&
              <li>
                <Link to="" className="has-arrow waves-effect">
                  <i className="ti-image"></i>
                  <span>Marketing</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={`${baseLink}/awards`}>Awards</Link>
                  </li>
                  <li>
                    <Link to="" className="has-arrow waves-effect">Embeds</Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to={`${baseLink}/marketing-assets`}>Marketing Assets</Link>
                      </li>
                      <li>
                        <Link to={`${baseLink}/widgets`}>Website Widgets</Link>
                      </li>
                      <li>
                        <Link to={`${baseLink}/email-widgets`}>Email Widgets</Link>
                      </li>
                      <li>
                        <Link to={`${baseLink}/reviews-page`}>Reviews Page</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            }

            {attractsReviews &&
              <li>
                <Link to="" className="has-arrow waves-effect">
                  <i className="ti-bar-chart"></i>
                  <span>Analytics</span>
                </Link>

                <ul className="sub-menu">
                  <li>
                    <Link to={`${baseLink}/analytics`}>Performance</Link>
                  </li>
                  <li>
                    {isPremium
                      ? <Link to={`${baseLink}/reviews/export`}>Export</Link>
                      : isAdmin
                        ? <Link to={`${baseLink}/reviews/export`} className="premium-feature">Export <PremiumFeature hideText /></Link>
                        : <p className="premium-feature">Export <PremiumFeature hideText /></p>
                    }
                  </li>
                </ul>
              </li>
            }

            <li>
              <Link to="" className="has-arrow waves-effect">
                <i className="ti-settings"></i>
                <span>Settings</span>
              </Link>
              <ul className="sub-menu">
                {attractsReviews &&
                  <li>
                    <Link to={`${baseLink}/admin/notifications`}>
                      Notifications
                    </Link>
                  </li>
                }
                <li>
                  <Link to={`${baseLink}/admin/users`}>
                    Users
                  </Link>
                </li>
                {
                  !isReseller ?
                    <li>
                      <Link to={`${baseLink}/billing#details--subscription`}>
                        <span>Billing</span>
                      </Link>
                    </li>
                    : null
                }
                {
                  isProduct ?
                    <li>
                      <Link to={`${baseLink}/admin/advanced/`}>
                        <span>Advanced</span>
                      </Link>
                    </li>
                    : null
                }
                {/* <li>
                <Link to={`${baseLink}/leads/purchase`}>
                  Account
                </Link>
              </li> */}
              </ul >
            </li >
          </>
        }

        {
          currentUser?.isAdmin &&
          <li>
            <Link to="" className="has-arrow waves-effect">
              <i className="ti-settings"></i>
              <span>Product Management</span>
            </Link>
            <ul className="sub-menu">
              {product &&
                <>
                  <li>
                    <Link to={`${baseLink}/admin`} style={{ textTransform: "capitalize" }}>Product admin</Link>
                  </li>

                  <li>
                    <Link to={`${baseLink}/price-per-lead-calculator`} style={{ textTransform: "capitalize" }}>Lead price calculator</Link>
                  </li>

                  {(isProduct || isDevice) &&
                    <li>
                      <Link to={`${baseLink}/categories`} style={{ textTransform: "capitalize" }}>Categories</Link>
                    </li>
                  }

                  <li>
                    <Link to={`${baseLink}/admin-stats`} style={{ textTransform: "capitalize" }}>Admin Stats</Link>
                  </li>

                  <li>
                    <Link to={`${baseLink}/visitor-stats`} style={{ textTransform: "capitalize" }}>Visitor Stats</Link>
                  </li>
                </>
              }
            </ul>
          </li>
        }

        {
          currentUser?.isAdmin &&
          <li>
            <Link to="" className="has-arrow waves-effect">
              <i className="ti-settings"></i>
              <span>Global Management</span>
            </Link>
            <ul className="sub-menu">

              <li>
                <Link to={'/admin/leads'}>Leads</Link>
              </li>
              <li>
                <Link to="" className="has-arrow waves-effect">
                  <span>Categories</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={'/admin/categories'}>Categories & Subjects</Link>
                  </li>
                  {/* <li>
                    <Link to={'/admin/categories-level-1'}>Top Level</Link>
                  </li>
                  <li>
                    <Link to={'/admin/categories-level-2'}>Level 2</Link>
                  </li> */}
                </ul>
              </li>
              <li>
                <Link to={`/admin/visitor-stats`}>Visitor Stats</Link>
              </li>
              <li>
                <Link to={`/admin/accounts`}>Accounts</Link>
              </li>
              <li>
                <Link to={`/admin/notifications`}>Notifications</Link>
              </li>
              <li>
                <Link to={`/admin/events`}>Events</Link>
              </li>

              <li>
                <Link to={'/admin'}>Exports</Link>
              </li>
            </ul>
          </li>
        }

      </ul >
      <div style={{ padding: "12px 20px", color: '#ccc' }} id="plan-details">
        {showMenu && plan &&
          <h5 style={{ fontWeight: 'normal', marginBottom: '0', textTransform: 'capitalize' }}>
            Plan: <a href="https://edtechimpact.com/pricing" target="_blank" style={{ fontSize: '1.1rem', color: 'white' }}>
              <span style={{ textDecoration: 'underline' }}>
                {plan.includes('pro') ? 'Pro' : plan.includes('free') ? 'Free' : 'Starter'}
              </span>
            </a>
          </h5>
        }

        {showMenu && isPremium && planExpiry && <p style={{ marginBottom: '0.5rem' }}>Expires {planExpiry}</p>}


        {showMenu && <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '0' }}>
          {!isPowerPlan
            ? (
              <>
                {balanceText}
                &nbsp;&nbsp;
                <Link className="top-up" to={`/products/${product.slug}/billing#details--topup`}>
                  <Button color="primary" size="sm" disabled={!isPremium}>Top&nbsp;Up</Button>
                </Link>
              </>
            )
            : null}
        </p>}

        <Logo style={{ marginTop: '0.5rem' }} src={logo} />
      </div>
    </SidebarMenuDiv >
  );
};

const Sidebar = ({ product, reseller, device, brand, location, type, isMobile, leads, history }) => {

  const showMenu = product && product.slug !== 'add-listing';

  const prevProduct = usePrevious(product);
  const prevType = usePrevious(type);

  useEffect(() => {
    if (showMenu && (!prevProduct || (prevType !== type))) {
      initMenu()
    }
  }, [product, reseller, device, brand])

  const initMenu = () => {
    if (type !== "condensed" || isMobile) {
      new MetisMenu("#side-menu");

      var matchingMenuItem = null;
      var ul = document.getElementById("side-menu");
      var items = ul.getElementsByTagName("a");
      for (var i = 0; i < items.length; ++i) {
        if (location.pathname === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    }
  }

  const activateParentDropdown = item => {
    item.classList.add("mm-active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active"); // li
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  return (
    <React.Fragment>
      {type !== "condensed" ? (
        <SimpleBar style={{ height: "100%", maxHeight: "100%" }}>
          <SidebarContent
            product={product}
            device={device}
            reseller={reseller}
            brand={brand}
            leads={leads}
            history={history}
          />
        </SimpleBar>
      ) : (
        <SidebarContent
          product={product}
          device={device}
          brand={brand}
          reseller={reseller}
          leads={leads}
          history={history}
        />
      )}
    </React.Fragment>
  );
}

const SidebarMenuDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  a {
    font-weight: normal;
  }

  .top-up {
    color: #ccc;
    text-decoration: underline !important;
  }
`;

const Logo = styled.img`
  max-width: 130px;
  margin: 0 auto;
  width: 100%;
`;

const mapStateToProps = state => {
  const { product, leads } = state.Product;
  const { reseller } = state.Reseller;
  const { device } = state.Device;
  const { brand } = state.Brand;
  return { product, reseller, device, brand, leads };
};

export default compose(
  connect(
    mapStateToProps,
  ),
  withRouter,
)(Sidebar);
